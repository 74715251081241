import { DestinationFinderTheme } from '@supersonic/config';

export const destinationFinderTheme: DestinationFinderTheme = {
  temperatureIconBgColor: 'primary_lighter2',
  flightTimeIconBgColor: 'primary',
  dealCardPriceColor: 'secondary',
  promo: {
    title: 'The Destination Finder',
    text: 'Know what you want from your holiday but don’t know where to get it? Just ask our Destination Finder! We’ll narrow it down to your perfect destinations and find the best deals to get you there!',
    buttonText: 'Inspire me',
    url: '/trip-finder/v2/MAN+BHX+BRS+LGW+NCL+LBA+EMA+EDI/+60/7/2',
    imageOne: {
      borderColor: 'primary_darker1',
      path: 'https://cdn.sanity.io/images/hqzqrzyr/production-travelsupermarket/986cd36399242ad9bab629426af0133875fb2bd3-5495x3570.jpg',
    },
    imageTwo: {
      borderColor: 'primary',
      path: 'https://cdn.sanity.io/images/hqzqrzyr/production-travelsupermarket/8f2ae50323909683be49d7870450b2e79e055906-5472x3648.jpg',
    },
    imageThree: {
      borderColor: 'primary_lighter1',
      path: 'https://cdn.sanity.io/images/hqzqrzyr/production-travelsupermarket/53a3061524c8c98f620802e625586fc790da1650-5616x3975.jpg',
    },
  },
};
