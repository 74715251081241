import { AdUnitsMapping } from './types';

export const MOBILE_BREAKPOINT: googletag.SingleSizeArray = [320, 0];
export const DESKTOP_BREAKPOINT: googletag.SingleSizeArray = [1024, 0];

const repeatedUnits = (name: string) => {
  return {
    [`${name}/1`]: {
      mobileSize: [
        [300, 50],
        [300, 75],
        [320, 50],
        [320, 75],
      ],
      desktopSize: [[728, 90]],
    },
    [`${name}/2`]: {
      mobileSize: [[300, 250]],
      desktopSize: [
        [160, 600],
        [300, 600],
        [300, 250],
      ],
    },
    [`${name}/3`]: {
      mobileSize: [
        [300, 50],
        [300, 75],
        [320, 50],
        [320, 75],
      ],
      desktopSize: [
        [728, 90],
        [970, 250],
      ],
    },
    [`${name}/4`]: {
      mobileSize: [
        [300, 50],
        [300, 75],
        [320, 50],
        [320, 75],
      ],
      desktopSize: [[728, 90]],
    },
    [`${name}/5`]: {
      mobileSize: [[1, 5]],
      desktopSize: [[1, 5]],
    },
  };
};

const resultsUnits = (name: string) => {
  return {
    [`${name}/1`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [
        [300, 250],
        [300, 600],
      ],
    },
    [`${name}/2`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [[300, 250]],
    },
    [`${name}/3`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [[300, 250]],
    },
    [`${name}/4`]: {
      mobileSize: [
        [300, 250],
        [300, 600],
      ],
      desktopSize: [[300, 250]],
    },
    [`${name}/5`]: {
      mobileSize: [
        [300, 50],
        [320, 50],
        [300, 75],
        [320, 75],
      ],
      desktopSize: [[728, 90]],
    },
  };
};

export const HEADER_BIDDING_LOOKUPS: { [key: string]: string } = {
  'holiday-search': 'results',
  'carhire-search': 'results',
  'flights-search': 'results',
  'hotels-search': 'results',
  uk_search: 'results',
  'carhire-homepage': 'basic',
  'carhire-destination': 'basic',
  'flights-homepage': 'basic',
  'flights-destination': 'basic',
  'holidays-homepage': 'basic',
  'holidays-destination': 'basic',
  'hotels-homepage': 'basic',
  'hotels-destination': 'basic',
  'travel-insurance-homepage': 'basic',
  'travel-insurance-destination': 'basic',
  'cruises-homepage': 'basic',
  'cruises-destination': 'basic',
  'villa-holidays-homepage': 'basic',
  'villa-holidays-destination': 'basic',
  cottages: 'basic',
  'airport-transfers-homepage': 'basic',
  'airport-transfers-destination': 'basic',
  'deals/top-deals': 'top-deals',
};

export const AD_UNITS_MAPPING: AdUnitsMapping = {
  'blog/1': {
    mobileSize: [[300, 250]],
    desktopSize: [
      [300, 250],
      [300, 600],
    ],
  },
  'blog/2': {
    mobileSize: [[300, 250]],
    desktopSize: [
      [300, 250],
      [300, 600],
    ],
  },
  'blog/3': {
    mobileSize: [[300, 250]],
    desktopSize: [
      [300, 250],
      [728, 90],
    ],
  },
  'blog/4': {
    mobileSize: [
      [300, 50],
      [320, 50],
      [300, 75],
      [320, 75],
    ],
    desktopSize: [[728, 90]],
  },
  'trip-finder/1': {
    mobileSize: [],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'trip-finder/2': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/1': {
    mobileSize: [
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/2': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/3': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 250],
      [970, 90],
      [728, 90],
    ],
  },
  'deals/top-deals/4': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [300, 600],
      [300, 250],
      [160, 600],
    ],
  },
  'deals/top-deals/5': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [300, 600],
      [300, 250],
      [160, 600],
    ],
  },
  'exposed-results/1': {
    mobileSize: [
      [300, 50],
      [320, 50],
      [300, 75],
      [320, 75],
    ],
    desktopSize: [[728, 90]],
  },
  'destinations-homepage/1': {
    mobileSize: [
      [300, 50],
      [320, 50],
      [300, 75],
      [320, 75],
    ],
    desktopSize: [[728, 90]],
  },
  'destinations-homepage/2': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 90],
      [728, 90],
    ],
  },
  'destinations-homepage/3': {
    mobileSize: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    desktopSize: [
      [970, 250],
      [970, 90],
      [728, 90],
    ],
  },
  ...resultsUnits('holiday-search'),
  ...resultsUnits('carhire-search'),
  ...resultsUnits('flights-search'),
  ...resultsUnits('hotels-search'),
  ...resultsUnits('uk_search'),
  ...repeatedUnits('carhire-homepage'),
  ...repeatedUnits('carhire-destination'),
  ...repeatedUnits('flights-homepage'),
  ...repeatedUnits('flights-destination'),
  ...repeatedUnits('holidays-homepage'),
  ...repeatedUnits('holidays-destination'),
  ...repeatedUnits('hotels-homepage'),
  ...repeatedUnits('hotels-destination'),
  ...repeatedUnits('cottages'),
  ...repeatedUnits('airport-transfers-homepage'),
  ...repeatedUnits('airport-transfers-destination'),
  ...repeatedUnits('travel-insurance-homepage'),
  ...repeatedUnits('travel-insurance-destination'),
  ...repeatedUnits('cruises-homepage'),
  'cruises-homepage/3': {
    mobileSize: [
      [300, 50],
      [300, 75],
      [320, 50],
      [320, 75],
      [300, 250],
    ],
    desktopSize: [
      [728, 90],
      [970, 250],
    ],
  },
  ...repeatedUnits('cruises-destination'),
  'cruises-destination/3': {
    mobileSize: [
      [300, 50],
      [300, 75],
      [320, 50],
      [320, 75],
      [300, 250],
    ],
    desktopSize: [
      [728, 90],
      [970, 250],
    ],
  },
  ...repeatedUnits('villa-holidays-homepage'),
  ...repeatedUnits('villa-holidays-destination'),
  'main-homepage/1': {
    mobileSize: [
      [320, 50],
      [320, 75],
      [300, 50],
      [300, 75],
    ],
    desktopSize: [[728, 90]],
  },
  'main-homepage/2': {
    mobileSize: [
      [320, 50],
      [320, 75],
      [300, 50],
      [300, 75],
    ],
    desktopSize: [
      [728, 90],
      [970, 250],
      [970, 90],
    ],
  },
  'uk-content/1': {
    mobileSize: [
      [320, 50],
      [320, 100],
    ],
    desktopSize: [
      [728, 90],
      [970, 90],
    ],
  },
  'uk-content/2': {
    mobileSize: [
      [320, 50],
      [320, 100],
      [320, 250],
    ],
    desktopSize: [
      [320, 250],
      [728, 90],
      [970, 90],
    ],
  },
  'uk-content/3': {
    mobileSize: [
      [320, 50],
      [320, 100],
      [320, 250],
    ],
    desktopSize: [
      [320, 250],
      [728, 90],
      [970, 90],
      [970, 250],
    ],
  },
};
