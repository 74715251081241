import { ConfigCrmSignUp } from '@supersonic/config';

const crmSignUp: ConfigCrmSignUp = {
  title: 'Get our best deals straight to your inbox!',
  subTitle:
    'Sign up for huge holiday savings, hot deals and the chance to win holiday prizes!',
  emailFieldPlaceholder: 'Your email address',
  submitButtonText: 'Sign up',
  consentCompanyName: 'Icelolly Marketing Limited',
  consentText:
    'Icelolly Marketing Limited wish to send you marketing emails. If you consent, please tick to confirm.',
  legalText:
    "We won't pass your details on to anyone else. By clicking the sign up button you agree to our",
  backgroundColor: 'white',
  privacyPolicyLink: '/privacy-policy',
  privacyPolicyLinkColor: 'primary_darker1',
  termsOfUseLink: '/terms-and-conditions',
  termsOfUseLinkColor: 'primary_darker1',
};
export default crmSignUp;
