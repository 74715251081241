import { HolidayResultsTheme } from '@supersonic/config';

export const holidayResultsTheme: HolidayResultsTheme = {
  dividerColor: 'grey2',
  compareMoreRowColor: 'sand',
  linkColor: 'secondary',
  linkHoverColor: 'secondary_darker1',
  labelTextColor: 'grey6',
  priceTextColor: 'secondary',
  uspBackgroundColor: 'secondary_lighter5',
  uspIconColor: 'supporting2',
  outboundInboundLabelColor: 'secondary_darker2',
  flightInfoItemBackgroundColor: 'primary_lighter4',
  flightInfoItemTextColor: 'black',
  boardBasisBackgroundColor: 'sand',
  yourSelectedPropertyBackgroundColor: 'primary',
};
